/** @jsx jsx */
/**
 * Fresh Pets Nav
 *
 * @export Nav
 */
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu from 'react-burger-menu/lib/menus/stack'
import { Flex, jsx, NavLink, useThemeUI } from 'theme-ui'
import FreshPetsDog from '../../assets/svg/fresh-pets-logo-dog.svg'
import { useApp } from '../../data/app-context'
import Link from '../link/link'

const Nav = () => {
    const [state] = useApp()
    const { menuOpen = false } = state

    const context = useThemeUI()
    const { theme } = context

    const {
        space = [],
        colors = {},
        fontSizes = [],
        fonts = {},
        alpha = {}
    } = theme

    const PrimaryNavStyles = {
        bmBurgerButton: {
            position: 'fixed',
            height: space[3],
            width: space[3],
            left: '36px',
            top: '36px'
        },
        bmCrossButton: {
            height: space[3],
            width: space[3]
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            background: colors.primary_dark,
            padding: `${space[4]}px ${space[2]}px ${space[0]}px`,
            fontSize: `${fontSizes[3]}px`
        },
        bmItemList: {
            padding: `${space[3]}px`
        },
        bmItem: {
            outline: 'none',
            display: 'block',
            background: 'none',
            fontFamily: fonts.heading
        },
        bmOverlay: {
            background: alpha.dark
        }
    }

    return (
        <Menu
            styles={PrimaryNavStyles}
            isOpen={menuOpen}
            onStateChange={newMenuState => {
                state.stateChangeHandler(newMenuState)
            }}
            customCrossIcon={
                <FontAwesomeIcon
                    sx={{
                        color: 'white'
                    }}
                    onClick={() => state.toggleMenu}
                    icon={faTimes}
                />
            }
            customBurgerIcon={false}>
            <Flex
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    px: 2,
                    color: 'white',
                    bg: 'black'
                }}>
                <Flex
                    sx={{
                        pl: 3,
                        pb: 3,
                        justifyContent: 'flex-start'
                    }}>
                    <Link aria-label='Home' to='/' onClick={state.toggleMenu}>
                        <FreshPetsDog
                            sx={{
                                fill: 'white',
                                width: ['95px', '110px', '145px']
                            }}
                        />
                    </Link>
                </Flex>
                <Flex
                    as='nav'
                    sx={{
                        height: '100%',
                        alignItems: 'flex-start',
                        flexDirection: 'column'
                    }}>
                    <NavLink
                        as={Link}
                        to='/services/'
                        onClick={state.toggleMenu}>
                        Services
                    </NavLink>
                    <NavLink
                        as={Link}
                        to='/gallery/'
                        onClick={state.toggleMenu}>
                        Gallery
                    </NavLink>
                    <NavLink as={Link} to='/team/' onClick={state.toggleMenu}>
                        Team
                    </NavLink>
                    <NavLink as={Link} to='/faq/' onClick={state.toggleMenu}>
                        FAQ
                    </NavLink>
                </Flex>
            </Flex>
        </Menu>
    )
}

export default Nav
