/** @jsx jsx */
/**
 *
 * FreshPets Header
 *
 */

import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { lighten } from '@theme-ui/color'
import Headroom from 'react-headroom'
import { Box, Container, Flex, jsx } from 'theme-ui'
import { useApp } from '../../data/app-context'
import Link from '../link/link'
import Logo from '../logo/logo'

const Header = () => {
    const [state] = useApp()

    return (
        <Headroom sx={{ mb: 0 }}>
            <Flex
                as='header'
                sx={{
                    flexDirection: 'row',
                    backgroundColor: 'primary',
                    py: [3, 4],
                    justifyContent: 'center'
                }}>
                <Container>
                    <Flex
                        sx={{
                            justifyContent: 'flex-start',
                            // justifyContent: 'space-between',
                            // flexDirection: 'row-reverse',
                            alignItems: 'center'
                        }}>
                        <Box
                            sx={{
                                pl: 3,
                                pr: [2, 3, 4]
                            }}>
                            <FontAwesomeIcon
                                sx={{
                                    fontSize: [4, 5, 6],
                                    color: 'white',
                                    transition: ({ durations }) =>
                                        `color ${durations[1]} ease`,
                                    '&:hover': {
                                        color: ({ ratios }) =>
                                            lighten('primary_light', ratios[0])
                                    }
                                }}
                                onClick={state.toggleMenu}
                                icon={faBars}
                            />
                        </Box>
                        <Link
                            aria-label='Home'
                            to='/'
                            style={{
                                color: `white`,
                                textDecoration: `none`
                            }}>
                            <Logo />
                        </Link>
                    </Flex>
                </Container>
            </Flex>
        </Headroom>
    )
}

export default Header
