/**
 *
 * FreshPets Global Styles
 *
 */
import { Global } from '@emotion/react'
import React from 'react'
import { useThemeUI } from 'theme-ui'
import ComfortaaRegularWoff from '../../assets/fonts/comfortaa/Comfortaa-Regular.woff'
import ComfortaaRegularWoff2 from '../../assets/fonts/comfortaa/Comfortaa-Regular.woff2'
import TextaRegularWoff from '../../assets/fonts/texta/Texta-Regular-webfont.woff'
import TextaRegularWoff2 from '../../assets/fonts/texta/Texta-Regular-webfont.woff2'

const GlobalStyles = () => {
    const context = useThemeUI()
    const {
        theme: { fonts = {}, space = [], fontSizes = [] }
    } = context

    // Base reset
    const baseStyles = {
        body: {
            margin: space[0],
            padding: space[0],
            // backgroundColor: colors.background_dark,
            fontFamily: fonts.body,
            fontSize: fontSizes[3]
            // minWidth: sizes.body.width
        }
    }

    // Texta Regular
    const TextaRegularFont = {
        '@font-face': {
            fontFamily: 'texta_regular',
            fontDisplay: 'swap',
            src: `url(${TextaRegularWoff2}) format('woff2'),
                    url(${TextaRegularWoff}) format('woff')`
        }
    }

    // Comfortaa Regular
    const ComfortaaRegularFont = {
        '@font-face': {
            fontFamily: 'comfortaa_regular',
            fontDisplay: 'swap',
            src: `url(${ComfortaaRegularWoff}) format('woff2'),
                    url(${ComfortaaRegularWoff2}) format('woff')`
        }
    }

    // Compose styles
    return (
        <Global styles={[baseStyles, TextaRegularFont, ComfortaaRegularFont]} />
    )
}

export default GlobalStyles
