/** @jsx jsx */
/**
 *
 * FreshPets Hero
 *
 */

import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import React from 'react'
import { Alert, Box, Flex, jsx, Spinner } from 'theme-ui'

const BusinessAddressMap = ({ latitude, longitude, zoom }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.GATSBY_GOOGLE_CLOUD_PLATFORM_API_KEY
    })

    // Bail early on error
    if (loadError) {
        return (
            <Flex
                sx={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <Alert>Error loading map data</Alert>
            </Flex>
        )
    }

    return (
        <React.Fragment>
            <Flex
                sx={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                {isLoaded ? (
                    <Box
                        sx={{
                            width: '100%'
                        }}>
                        <GoogleMap
                            options={{
                                mapTypeControl: false,
                                fullscreenControl: false,
                                streetViewControl: false
                            }}
                            mapContainerStyle={{
                                height: '220px',
                                my: 2,
                                borderRadius: 2
                            }}
                            zoom={zoom}
                            center={{
                                lat: latitude,
                                lng: longitude
                            }}>
                            <Marker
                                position={{
                                    lat: latitude,
                                    lng: longitude
                                }}
                            />
                        </GoogleMap>
                    </Box>
                ) : (
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '220px'
                        }}>
                        <Spinner label='loading map' />
                    </Flex>
                )}
            </Flex>
        </React.Fragment>
    )
}

BusinessAddressMap.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    zoom: PropTypes.number.isRequired
}

export default BusinessAddressMap
