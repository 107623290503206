/** @jsx jsx */
/**
 *
 * FreshPets Logo
 *
 */
import { jsx } from 'theme-ui'
import FreshPetsLogo from '../../assets/svg/fresh-pets-logo.svg'
import { useSiteMetadata } from '../../hooks/use-site-metadata'

const Logo = () => {
    const { title = '', description = '' } = useSiteMetadata()

    return (
        <FreshPetsLogo
            sx={{
                px: [3, 2, 1],
                width: ['240px', '280px', '320px'],
                fill: 'white'
            }}
            alt={`${title} ${description}`}
        />
    )
}

export default Logo
