/** @jsx jsx */
/**
 *
 * FreshPets Footer
 *
 */

import { faFacebookF, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faMobile } from '@fortawesome/pro-duotone-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Container, Flex, Heading, jsx } from 'theme-ui'
import FreshPetsDog from '../../assets/svg/fresh-pets-logo-dog.svg'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import BusinessAddressMap from '../business-address-map/business-address-map'
import Link from '../link/link'

const Header = () => {
    const { title = '', description = '' } = useSiteMetadata()

    return (
        <Flex
            as='footer'
            sx={{
                backgroundColor: 'primary_dark',
                pt: [ 4, 5, 6 ],
                pb: [ 2, 3, 4 ],
                mt: 5,
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
            <Container>
                <Flex
                    sx={{
                        flexWrap: 'wrap',
                        px: [ 0, -3 ],
                        pt: 5
                    }}>
                    <Box
                        sx={{
                            width: [ '100%', '33.3%' ],
                            px: 3
                        }}>
                        <Heading
                            as='h4'
                            sx={{
                                color: 'white',
                                mb: 3,
                                fontSize: [ 5, 3, 5 ]
                            }}>
                            We're Here
                        </Heading>
                        <BusinessAddressMap
                            latitude={parseFloat(
                                process.env.GATSBY_GOOGLE_MAPS_LATITUDE
                            )}
                            longitude={parseFloat(
                                process.env.GATSBY_GOOGLE_MAPS_LONGITUDE
                            )}
                            zoom={16} />
                    </Box>
                    <Box
                        sx={{
                            width: [ '100%', '33.3%' ],
                            px: 3
                        }}>
                        <Heading
                            as='h4'
                            sx={{
                                color: 'white',
                                mb: 3,
                                fontSize: [ 5, 3, 5 ],
                                mt: [ 5, 0 ]
                            }}>
                            Follow Us
                        </Heading>
                        <Flex
                            sx={{
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                height: '220px'
                            }}>
                            <Flex>
                                <Box
                                    sx={{
                                        alignSelf: 'center'
                                    }}>
                                    <Box
                                        as='span'
                                        className='fa-layers fa-fw'
                                        sx={{ fontSize: [ 6, 5, 6 ] }}>
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={faCircle}
                                            sx={{
                                                color: 'primary_light',
                                                opacity: 0.3
                                            }}
                                            transform='grow-4' />
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={faInstagram}
                                            sx={{ color: 'white' }}
                                            transform='shrink-6' />
                                    </Box>
                                </Box>
                                <Flex
                                    sx={{
                                        flexDirection: 'column',
                                        px: 2,
                                        justifyContent: 'center'
                                    }}>
                                    <Link
                                        aria-label='Fresh Pets Instagram'
                                        rel='noopener noreferrer'
                                        to={process.env.GATSBY_INSTAGRAM_URL}
                                        target='_blank'
                                        sx={{ variant: 'links.footer' }}>
                                        freshpetsgrooming
                                    </Link>
                                </Flex>
                            </Flex>
                            <Flex>
                                <Box
                                    sx={{
                                        alignSelf: 'center'
                                    }}>
                                    <Box
                                        as='span'
                                        className='fa-layers fa-fw'
                                        sx={{ fontSize: [ 6, 5, 6 ] }}>
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={faCircle}
                                            sx={{
                                                color: 'primary_light',
                                                opacity: 0.3
                                            }}
                                            transform='grow-4' />
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={faTwitter}
                                            sx={{ color: 'white' }}
                                            transform='shrink-6' />
                                    </Box>
                                </Box>
                                <Flex
                                    sx={{
                                        flexDirection: 'column',
                                        px: 2,
                                        justifyContent: 'center'
                                    }}>
                                    <Link
                                        aria-label='Fresh Pets Instagram'
                                        rel='noopener noreferrer'
                                        to={process.env.GATSBY_TWITTER_URL}
                                        target='_blank'
                                        sx={{ variant: 'links.footer' }}>
                                        freshpetsiow
                                    </Link>
                                </Flex>
                            </Flex>
                            <Flex>
                                <Box
                                    sx={{
                                        alignSelf: 'center'
                                    }}>
                                    <Box
                                        as='span'
                                        className='fa-layers fa-fw'
                                        sx={{ fontSize: [ 6, 5, 6 ] }}>
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={faCircle}
                                            sx={{
                                                color: 'primary_light',
                                                opacity: 0.3
                                            }}
                                            transform='grow-4' />
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={faFacebookF}
                                            sx={{ color: 'white' }}
                                            transform='shrink-6' />
                                    </Box>
                                </Box>
                                <Flex
                                    sx={{
                                        flexDirection: 'column',
                                        px: 2,
                                        justifyContent: 'center'
                                    }}>
                                    <Link
                                        aria-label='Fresh Pets Facebook'
                                        rel='noopener noreferrer'
                                        to={process.env.GATSBY_FACEBOOK_URL}
                                        target='_blank'
                                        sx={{
                                            variant: 'links.footer'
                                        }}>
                                        freshpetsiow
                                    </Link>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Box>
                    <Box
                        sx={{
                            width: [ '100%', '33.3%' ],
                            px: 3
                        }}>
                        <Flex sx={{ flexDirection: 'column' }}>
                            <Heading
                                as='h4'
                                sx={{
                                    color: 'white',
                                    mb: 3,
                                    fontSize: [ 5, 3, 5 ],
                                    mt: [ 5, 0 ]
                                }}>
                                Get In Touch
                            </Heading>
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly',
                                    height: '220px'
                                }}>
                                <Flex>
                                    <Box
                                        sx={{
                                            alignSelf: 'center'
                                        }}>
                                        <Box
                                            as='span'
                                            className='fa-layers fa-fw'
                                            sx={{ fontSize: [ 6, 5, 6 ] }}>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faCircle}
                                                sx={{
                                                    color: 'primary_light',
                                                    opacity: 0.3
                                                }}
                                                transform='grow-4' />
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faMobile}
                                                sx={{ color: 'white' }}
                                                transform='shrink-6' />
                                        </Box>
                                    </Box>
                                    <Flex
                                        sx={{
                                            flexDirection: 'column',
                                            px: 2,
                                            justifyContent: 'center'
                                        }}>
                                        <Link
                                            aria-label='Fresh Pets phone'
                                            rel='noopener noreferrer'
                                            to={`tel://${process.env.GATSBY_CONTACT_PHONE}`}
                                            target='_blank'
                                            sx={{
                                                variant: 'links.footer'
                                            }}>
                                            {process.env.GATSBY_CONTACT_PHONE}
                                        </Link>
                                    </Flex>
                                </Flex>

                                <Flex>
                                    <Box
                                        sx={{
                                            alignSelf: 'center'
                                        }}>
                                        <Box
                                            as='span'
                                            className='fa-layers fa-fw'
                                            sx={{ fontSize: [ 6, 5, 6 ] }}>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faCircle}
                                                sx={{
                                                    color: 'primary_light',
                                                    opacity: 0.3
                                                }}
                                                transform='grow-4' />
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faWhatsapp}
                                                sx={{ color: 'white' }}
                                                transform='shrink-6' />
                                        </Box>
                                    </Box>
                                    <Flex
                                        sx={{
                                            flexDirection: 'column',
                                            px: 2,
                                            justifyContent: 'center'
                                        }}>
                                        <Link
                                            aria-label='Fresh Pets WhatsApp'
                                            rel='noopener noreferrer'
                                            to={'https://wa.me/7801255796?text=Fresh%20Pets%20Website%20Enquiry'}
                                            target='_blank'
                                            sx={{
                                                variant: 'links.footer'
                                            }}>
                                            {process.env.GATSBY_CONTACT_PHONE}
                                        </Link>
                                    </Flex>
                                </Flex>
                                <Flex>
                                    <Box
                                        sx={{
                                            alignSelf: 'center'
                                        }}>
                                        <Box
                                            as='span'
                                            className='fa-layers fa-fw'
                                            sx={{ fontSize: [ 6, 5, 6 ] }}>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faCircle}
                                                sx={{
                                                    color: 'primary_light',
                                                    opacity: 0.3
                                                }}
                                                transform='grow-4' />
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faEnvelope}
                                                sx={{ color: 'white' }}
                                                transform='shrink-6' />
                                        </Box>
                                    </Box>
                                    <Flex
                                        sx={{
                                            flexDirection: 'column',
                                            px: 2,
                                            justifyContent: 'center'
                                        }}>
                                        <Link
                                            aria-label='Fresh Pets email'
                                            rel='noopener noreferrer'
                                            to={`mailto:${process.env.GATSBY_CONTACT_EMAIL}?subject=Fresh%20Pets%20Website%20Enquiry`}
                                            target='_blank'
                                            sx={{
                                                variant: 'links.footer'
                                            }}>
                                            {process.env.GATSBY_CONTACT_EMAIL}
                                        </Link>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Box>
                </Flex>

                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 6
                    }}>
                    <Flex>
                        <FreshPetsDog
                            sx={{
                                pl: [ 3, 2, 1 ],
                                width: [ '100px', '140px', '180px' ],
                                fill: 'primary'
                            }}
                            alt={`${title} ${description}`} />
                    </Flex>
                    <Flex
                        sx={{
                            mt: 6,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <Link
                            to='/'
                            sx={{
                                variant: 'links.footer',
                                fontSize: [ 3, 4 ]
                            }}>
                            &copy; {title} {new Date().getFullYear()}
                        </Link>
                        <Flex
                            sx={{
                                flexDirection: [ 'column', 'row' ],
                                alignItems: 'center',
                                mt: [ 3, 0 ]
                            }}>
                            <Link
                                to='/privacy/'
                                sx={{
                                    variant: 'links.small',
                                    mx: 1,
                                    mt: 2
                                }}>
                                Privacy Policy
                            </Link>
                            <Link
                                to='/terms/'
                                sx={{
                                    variant: 'links.small',
                                    mx: 1,
                                    mt: 2
                                }}>
                                Terms & Conditions
                            </Link>
                        </Flex>
                    </Flex>
                    <Flex>
                        <FreshPetsDog
                            sx={{
                                pl: [ 3, 2, 1 ],
                                width: [ '100px', '140px', '180px' ],
                                fill: 'primary',
                                transform: 'scale(-1,1)'
                            }}
                            alt={`${title} ${description}`} />
                    </Flex>
                </Flex>
            </Container>
        </Flex>
    )
}

export default Header
