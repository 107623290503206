/**
 *
 * FreshPets Layout
 *
 */

import PropTypes from 'prop-types'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import Footer from '../footer/footer'
import GlobalStyles from '../global-styles/global-styles'
import Header from '../header/header'
import Nav from '../nav/nav'

const Layout = ({ children }) => (
    <React.Fragment>
        <GlobalStyles />
        <Nav />
        <Flex
            sx={{
                flexDirection: 'column',
                minHeight: '100vh'
            }}>
            <Header />
            <Box sx={{ flex: '1 1 auto' }}>{children}</Box>
            <Footer />
        </Flex>
    </React.Fragment>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
